import React from 'react'
import "./style.css"

function PolicyPage() {
  return (
    <div id="privacy_page" className='full-screen'>
      <div className='col-md-6 m-auto p-3'>
        <h1>Informativa sul trattamento dei dati personali</h1>

        <h3>Titolare del trattamento</h3>
        <p>Il Titolare del trattamento è  Raggiungibile Snc con sede legale in Vicolo Nuovo, 4 30027 San Donà di Piave (VE). 
          <br/>Per l'esercizio dei diritti riconosciuti dal REGOLAMENTO (UE) 2016/679 (di seguito “GDPR” o “Regolamento”) o per chiedere qualsiasi chiarimento relativo al trattamento dei dati personali, è possibile contattare il Titolare ai seguenti recapiti: tel 3791888263, <u><a href='mailto:info@libreriaraggiungibile.it'>info@libreriaraggiungibile.it</a></u>
        </p>

        <h3>Sicurezza</h3>
        <p>Il Titolare ha adottato misure tecniche e organizzative tali da garantire un livello di sicurezza adeguato al rischio e al rispetto della normativa sul trattamento dei dati personali.</p>
        
        <br/>
        <h1>Informazioni sui tipi di dati trattati</h1>
        <h3>Iscrizione alla Newsletter</h3>
        <p>L’iscrizione volontaria e facoltativa alla newsletter comporta il trattamento dei dati di contatto dell’Interessato per l’invio di periodiche comunicazioni elettroniche inerenti l’attività, i servizi e le offerte del Titolare.</p>
        <ul>
          <li>
            <b>Finalità e base giuridica del trattamento (GDPR Art. 13, paragrafo 1, lett. c)</b>
            <p>La finalità è perfezionare l’iscrizione alla newsletter per ricevere periodiche comunicazioni elettroniche inerenti l’attività, i servizi e le offerte del Titolare. La base giuridica del trattamento è il consenso dell’Interessato (art. 6, par.1, lett. a, GDPR).</p>
          </li>
          <li>
            <b>Ambito di comunicazione (GDPR Art. 13, paragrafo 1, lett. e, f)</b>
            <p>I dati sono trattati esclusivamente da soggetti regolarmente autorizzati e istruiti al trattamento. Il Titolare garantisce che, nell'erogazione del servizio,  i dati non saranno trasferiti al di fuori dello Spazio Economico Europeo (nemmeno per quanto attiene alle operazioni svolte da eventuali sub responsabili).</p>
          </li>
          <li>
            <b>Periodo di conservazione dei dati (GDPR Art. 13, paragrafo 2, lett. a)</b>
            <p>I dati saranno conservati sino alla richiesta di cancellazione da parte dell’Interessato.</p>
          </li>
          <li>
            <b>Conferimento (GDPR Art. 13, paragrafo 2, lett. e)</b>
            <p>Il mancato conferimento dei dati obbligatori comporterà l’impossibilità di iscriversi alla newsletter da parte dell’Interessato.</p>
          </li>
        </ul>
        <h3>Diritti dell'interessato</h3>
        <p>Ai sensi degli artt. 15 e ss. GDPR, con riferimento ai dati personali che lo riguardano, l'Interessato ha diritto di:</p>
        <ul>
          <li>chiedere al Titolare del trattamento l'accesso ed ottenerne copia, nonché conoscerne finalità, periodo di conservazione ed eventuali destinatari;</li>
          <li>ottenere la rettifica dei dati personali inesatti e l'integrazione di quelli incompleti, senza ingiustificato ritardo;</li>
          <li>ottenere la limitazione del trattamento, nelle ipotesi previste dall'art. 18 GDPR, alla sola conservazione o alle sole operazioni per le quali sia prestato esplicito consenso, nonché alle ipotesi in cui esso sia necessario alla salvaguardia di diritti in sede giudiziaria, alla tutela di interessi pubblici o di diritti di terzi soggetti;</li>
          <li>ottenerne la cancellazione, senza ingiustificato ritardo, qualora:</li>
          <ul>
            <li>i dati non siano più necessari per le finalità indicate;</li>
            <li>sia stato revocato il consenso al trattamento;</li>
            <li>i dati siano stati trattati illecitamente;</li>
            <li>la cancellazione sia necessaria per adempiere ad un obbligo di legge e nelle altre ipotesi previste dall'art 17 GDPR;</li>
          </ul>
          <li>opporsi al trattamento dei dati personali che lo riguardano, ai sensi dell'art. 21 GDPR, in particolare a quello avente finalità di marketing diretto, con conseguente impossibilità di trattare ulteriormente i dati per tale scopo;</li>
          <li>ricevere, dal Titolare del trattamento, i dati senza impedimenti, in un formato strutturato di uso comune, leggibile da dispositivo automatico, al fine di trasmetterli ad altro titolare (c.d. diritto alla portabilità);</li>
          <li>revocare in qualsiasi momento il consenso al trattamento dei dati già prestato, senza che ciò pregiudichi la liceità del trattamento sino a tale momento espletato;</li>
        </ul>
        <p>L'esercizio dei predetti diritti può essere espletato contattando il Titolare del trattamento ai recapiti indicati.</p>

        <h3>Diritto di reclamo</h3>
        <p>L'interessato che ritenga che il trattamento dei suoi dati personali avvenga in violazione di quanto previsto dal GDPR, ha il diritto di proporre reclamo alla competente autorità di controllo.</p>
      </div>
    </div>
  )
}

export default PolicyPage