import React from 'react'
import "./style.css"

function ErrorPage() {
  return (
    <div id="error_page" className='full-screen centered'>
      <div className="centered text-center">
        <h1>404</h1>
        <p>Pagina non trovata</p>
      </div>
    </div>
  )
}

export default ErrorPage