import React, { useEffect, useRef, useState } from 'react'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';
import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import "./style.css"

function NewsLetterPage() {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")
  const [emailError, setEmailError] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const [isPrivacyAccepted, setIsPrivacyAccepted] = useState(false)
  const [privacyError, setPrivacyError] = useState(false)
  const [loadingIframe, setLoadingIframe] = useState(false)
  const form = useRef()
  const iframe = useRef()

  function submitMail() {
    let regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    let isEmailValid =  regex.test(email);
    let isNameEmpty = name == ""
    let isSurnameEmpty = surname == ""
    if(isEmailValid && !isSurnameEmpty && !isNameEmpty && isPrivacyAccepted) {
      setEmailSent(true)
      form.current.submit()
      setLoadingIframe(true)
      // iframe.current.style.minHeight = "100vh"
    } else if(!isEmailValid) {
        setEmailError(true)
        setTimeout(() => setEmailError(false), 3000)
    } else if(!isPrivacyAccepted) {
      setPrivacyError(true)
    }
  }

  useEffect(() => {
    if(isPrivacyAccepted) setPrivacyError(false)
  }, [isPrivacyAccepted])
  

  return (
    <div id="newsletter_page" className='full-screen centered p-2'>
      <div className='content text-center col-md-6 m-auto p-2'>
        <h1 className='main_title'>NewsLetter</h1>
        <h2 className='mt-5'>Le notizie della Raggiungibile</h2>
        <br/>
        <p className='description'>Iscriviti subito alla newsletter mensile della libreria per essere sempre aggiornatə su eventi, nuovi arrivi, promozioni e novità.</p>
        <br/>
        {
          !emailSent 
          ? <>
              <TextField 
                label="Nome"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                fullWidth
                helperText={name == "" && "Riempi questo campo"}
                placeholder='Inserisci il nome...'
                className='mb-4'
                />
              <TextField 
                label="Cognome"
                variant="outlined"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                required
                fullWidth
                helperText={surname == "" && "Riempi questo campo"}
                placeholder='Inserisci il nome...'
                className='mb-4'
                />
              <TextField 
                label="Email" 
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                fullWidth
                error={emailError}
                helperText={emailError && "Formato della email non valido"}
                placeholder='Inserisci la tua email...'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </> 
            : <p>Se sei nuovo qui, <b>conferma</b> la tua iscrizione nella mail che ti abbiamo mandato all'indirizzo {email}!<br style={{marginTop: "10px"}}/> Se sei già iscrittə, potrai goderti la nostra newsletter senza ulteriori passaggi!</p>
          }
        <br/>
        <br/>
        { privacyError && <u><p>Accetta il trattamento dei dati</p></u> }
        {
          !emailSent &&
            <><input type='checkbox' value={isPrivacyAccepted} onChange={(e) => setIsPrivacyAccepted(e.target.checked)}/> Confermo di aver preso visione dell'<u><Link to="/privacy">informativa</Link></u> e presto il consenso al trattamento dei dati.</>
        }
        <br/>
        <br/>
        <span style={{display: "inline-block", backgroundColor: "transparent", borderRadius: "4px"}}>
          <img class="refferal_badge" src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg" alt="Intuit Mailchimp" style={{width: "220px", height: "40px", display: "flex", padding: "2px 0px", justifyContent: "center", alignItems: "center"}}/>
        </span>
        <br/>
        {/*  */}
        <button className='subscribe_button bounce' onClick={submitMail}>Iscriviti</button>
        <br/><br/>
        <Link to='/'><p className='home_button bounce'>Torna alla Home</p></Link>
      </div>
      <iframe ref={iframe} id="myIframe" name="myIframe" src="about:blank" style={{display: "none"}} onLoad={() => setLoadingIframe(false)}/>

      <div id="mc_embed_shell">
        <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css"/>
        <style type="text/css">
          {"#mc_embed_signup{background:#fff; false;clear:left; font:14px Helvetica,Arial,sans-serif; width: 600px;"}
        </style>
        <div id="mc_embed_signup">
          <form ref={form} action="https://libreriaraggiungibile.us21.list-manage.com/subscribe/post?u=7d2a96a542be1243ec74b246f&amp;id=0154021b08&amp;f_id=00f6f0e6f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" novalidate="" target="myIframe">
            <div id="mc_embed_signup_scroll"><h2>Iscriviti alla nostra newsletter</h2>
              <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
              <div class="mc-field-group">
                <label for="mce-NOME">Nome</label>
                <input type="text" name="NOME" class=" text" id="mce-NOME" value={name} />
              </div>
              <div class="mc-field-group">
                <label for="mce-COGNOME">Cognome</label>
                <input type="text" name="COGNOME" class=" text" id="mce-COGNOME" value={surname} />
              </div>
              <div class="mc-field-group">
                <label for="mce-EMAIL">Indirizzo email <span class="asterisk">*</span></label>
                <input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value={email} />
              </div>
              <div hidden="">
                <input type="hidden" name="tags" value="2978859" />
              </div>
              <div id="mce-responses" class="clear foot">
                  <div class="response" id="mce-error-response" style={{display: "none"}}></div>
                  <div class="response" id="mce-success-response" style={{display: "none"}}></div>
              </div>
              <div aria-hidden="true" style={{position: "absolute", left: "-5000px"}}>
                <input type="text" name="b_7d2a96a542be1243ec74b246f_0154021b08" tabindex="-1" value=""/>
              </div>
              <div class="optionalParent">
                <div class="clear foot">
                  <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Subscribe" />
                    <p style={{margin: "0px auto"}}>
                      <a href="http://eepurl.com/iJeGxE" title="Mailchimp: l’email marketing è facile e divertente">
                        <span style={{display: "inline-block", backgroundColor: "transparent", borderRadius: "4px"}}>
                          <img class="refferal_badge" src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg" alt="Intuit Mailchimp" style={{width: "220px", height: "40px", display: "flex", padding: "2px 0px", justifyContent: "center", alignItems: "center"}}/>
                        </span>
                      </a>
                    </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default NewsLetterPage