import React from 'react'
import { Timeline } from 'primereact/timeline';
import { TabView, TabPanel } from 'primereact/tabview';
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Carousel } from 'primereact/carousel';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import FestivalOutlinedIcon from '@mui/icons-material/FestivalOutlined';

import './style.css'

function MyTimeline(props) {
  let info = props.info
  let mobileMode = props.mobileMode
  let friday = info[0]
  let saturday = info[1]
  let sunday = info[2]

  const responsiveOptions = [
    // {
    //     breakpoint: '1199px',
    //     numVisible: 1,
    //     numScroll: 1
    // },
    // {
    //     breakpoint: '991px',
    //     numVisible: 2,
    //     numScroll: 1
    // },
    // {
    //     breakpoint: '767px',
    //     numVisible: 1,
    //     numScroll: 1
    // }
  ];

  return (
    <div className="my_timeline">
      {/* <TabView scrollable={!mobileMode} panelContainerClassName="tab_view" renderActiveOnly>
        <TabPanel header="Venerdì 22/09" headerTemplate={(options) => <HeaderTemplate options={options}/>}>
          <ScrollPanel style={{height: "calc(90vh - 104px)"}}>
            <br/>
            <Timeline value={friday} align="left" content={(item) => <ActivityCard info={item} />} className="w-full"/>
          </ScrollPanel>
        </TabPanel>
        <TabPanel header="Sabato 23/09" headerTemplate={(options) => <HeaderTemplate options={options}/>}>
          <ScrollPanel style={{height: "calc(90vh - 104px)"}}>
            <br/>
            <Timeline value={saturday} align="left" content={(item) => <ActivityCard info={item} />} className="w-full"/>
          </ScrollPanel>
        </TabPanel>
        <TabPanel header="Domenica 24/09" headerTemplate={(options) => <HeaderTemplate options={options}/>}>
          <ScrollPanel style={{height: "calc(90vh - 104px)"}}>
            <br/>
            <Timeline value={sunday} align="left" content={(item) => <ActivityCard info={item} />} className="w-full"/>
           </ScrollPanel> 
        </TabPanel>
      </TabView> */}
      <Carousel
        showIndicators={false}
        value={info}
        numScroll={1}
        numVisible={1}
        responsiveOptions={responsiveOptions}
        itemTemplate={(info) => <DailyProgram info={info} />} />
    </div>
  )
}

function DailyProgram(props) {
  let info = props.info
  let title = info.title
  let events = info.events

  return (
    // <ScrollPanel style={{height: "calc(90vh)"}} onScroll={() => console.log("ciao")}>
      <div>
      <div style={{background: "transparent", marginRight: 30}}>
        <h1 className="m-0 header_title">{title}</h1>
      </div>
      <br/>
      <div className="scrollable_program">
        <Timeline value={events} align="left" content={(item) => <ActivityCard info={item} />} className="w-full"/>
      </div>
      </div>

    // </ScrollPanel> 
  )
}

function ActivityCard(props) {
  let info = props.info

  return (
    <div className="activity_card mb-4">
      <h4 className="title m-0">{info.speaker}</h4>
      <i><p className="title m-0 mb-2">{info.title}</p></i>
      <p className="time m-0"><AccessTimeIcon style={{marginRight: "10px"}} />{info.time}</p>
      {info.what ? <p className="what m-0"><FestivalOutlinedIcon style={{marginRight: "10px"}}/>{info.what}</p> : ""}
      {info.place ? <p className="place m-0"><PlaceOutlinedIcon style={{marginRight: "10px"}}/>{info.place}</p> : ""}
      {
        info.description ? 
          <Inplace closable className='mt-2' closeIcon={() => <p className="m-0">Mostra meno</p>}>
            <InplaceDisplay>Mostra di più</InplaceDisplay>
            <InplaceContent><p className="description" dangerouslySetInnerHTML={{__html: info.description}}></p></InplaceContent>
          </Inplace> 
          : 
          ""
      }
    </div>
  )
}

function HeaderTemplate(props) {
  let options = props.options

  return (
    <div onClick={options.onClick} style={{background: "transparent", marginRight: 30}} className={options.className}>
      <h1 className="m-0 header_title">{options.titleElement}</h1>
    </div>
  )
}

export default MyTimeline