import giulio_d_antona from '../../media/img/festival/host/giulio_d_antona.jpg';
import ale_puro from '../../media/img/festival/host/ale_puro.jpg';
import gioia_bergamo from '../../media/img/festival/host/gioia_bergamo.jpeg';
import nicolo_martin from '../../media/img/festival/host/nicolo_martin.jpeg';
import giulia_depentor from '../../media/img/festival/host/giulia_depentor.jpg';
import daniele_cassandro from '../../media/img/festival/host/daniele_cassandro.jpg';
import lisa_magoga from '../../media/img/festival/host/lisa_magoga.jpeg';
import enrico_marigonda from '../../media/img/festival/host/enrico_marigonda.jpg';
import marco_leoni from '../../media/img/festival/host/marco_leoni.jpg';
import federica_ortolan from '../../media/img/festival/host/federica_ortolan.jpg';
import susi_danesin from '../../media/img/festival/host/susi_danesin.jpg';
import marta_aidala from '../../media/img/festival/host/marta_aidala.jpg';
import claudio_marinaccio from '../../media/img/festival/host/claudio_marinaccio.jpg';
import paolo_celotto from '../../media/img/festival/host/paolo_celotto.jpg';
import enrica_bordignon from '../../media/img/festival/host/enrica_bordignon.jpeg';
import maria_chiara_gritti from '../../media/img/festival/host/maria_chiara_gritti.jpg';
import valentina_severin from '../../media/img/festival/host/valentina_severin.jpg';
import samuela_barbieri from '../../media/img/festival/host/samuela_barbieri.jpeg';
import carlo_zulianello from '../../media/img/festival/host/carlo_zulianello.jpg';
import paolo_malaguti from '../../media/img/festival/host/paolo_malaguti.jpg';
import giorgio_ricci from '../../media/img/festival/host/giorgio_ricci.jpeg';
import juls_criveller from '../../media/img/festival/host/juls_criveller.jpg';
import andrea_zelio from '../../media/img/festival/host/andrea_zelio.jpg';
import spezie_piccanti from '../../media/img/festival/host/spezie_piccanti.jpg';

// .ds_store is a system file and should not be included in the import statements.

let hostDescription = [
  {
    name: "Gioia Bergamo", // TODO: to ask
    avatar: gioia_bergamo,
    description: "Educatrice curiosa ed emozionata della meraviglia del mondo, dà vita a laboratori per bambini/e che attraverso l’utilizzo di materiale naturale stimolano curiosità, creatività, ingegno e collaborazione, offrendo inediti punti di vista verso il mondo che ci circonda! Laureata in scienze dell’educazione, cofondatrice ed educatrice presso il centro il nocciolo di Jesolo.",
    // privilege: true
  },
  {
    name: "Juls Criveller", // TODO: to ask
    avatar: juls_criveller,
    description: "Juls Criveller è visual designer e illustratrice indipendente. Nel suo lavoro emerge la necessità di un ritorno al mondo dell’artigianato e del fatto a mano. Specializzata in hand lettering, ama dar forma e colore alle lettere attraverso i suoi workshop di ricamo, che organizza parallelamente ai suoi progetti. Ama sperimentare su superfici e tessuti: da questa passione è nato il brand di abbigliamento sostenibile Laju Slow Apparel, di cui è co-fondatrice, menzionato su Vogue. È autrice di immagini del libro “La Sessualità spiegata ai bambini di ieri, oggi e domani” (2022) e 'Per Piacere' (2024), editore Beccogiallo.",
    // privilege: true
  },
  {
    name: "Marta Aidala",
    avatar: marta_aidala,
    description: "Nata a Torino nel 1996, a diciassette anni si è innamorata delle montagne e il suo sogno è salirci per rimanere. Dopo i lavori più disparati, dalla project manager alla cameriera, ha frequentato la scuola Holden e si è diplomata nel 2023. Adesso scrive romanzi, articoli, racconti e lavora in una libreria.",
    // privilege: true
  },
  {
    name: "Daniele Cassandro",
    avatar: daniele_cassandro,
    description: "È nato a Roma nel 1970. Come giornalista ha lavorato in redazione a GQ, Riders e Wired e ha collaborato con Rockstar e Vogue. Oggi lavora con Internazionale ed è una delle voci di Pagina 3 Internazionale su Radio 3.",
    // privilege: true
  },
  {
    name: "Susi Danesin",
    avatar: susi_danesin,
    description: "Autrice, attrice e formatrice teatrale, da anni si occupa di teatro ragazzi e promozione della lettura. Autrice di “Dlin, dlon, ops, vrum! Scalda la voce, fai una saltello e accendi questo libro” e “Ping pong- una partita di storie” illustrazioni di Francesca Carabelli, edizioni Parapiglia.",
    // privilege: true
  },
  {
    name: "Lisa Magoga",
    avatar: lisa_magoga,
    description: "Sono Lisa Magoga, mi piace giocare con i bambini e credo sia questo il principale motivo per cui è diventata la mia professione. Dopo gli studi pedagogici, ho intrapreso dei percorsi accademici, ma non solo, legati al mondo della letteratura per l’infanzia; parallelamente ho cominciato ad occuparmi e a promuovere progetti di lettura ad alta voce rivolta ai bambini e alle bambine di tutte le età, specializzandomi nella fascia dei piccolissimi (0-3).",
    // privilege: true
  },
  {
    name: "Valentina Severin",
    avatar: valentina_severin,
    description: "Laureata in Filosofia Teoretica, dell'Arte e della Comunicazione (2010), con un diploma di Master in Consulenza Filosofica (2017), porto in giro la filosofia 'praticata' attraverso l'associazione Sophratica. Consulenza Filosofica, della quale sono presidente dal 2019. Tra le collaborazioni più recenti, quella con Pop Sophia in occasione del Festival omonimo ad Ancona a marzo 2024."
    // privilege: true
  },
  {
    name: "Giulia Chinellato",
    // avatar: giulia_c // TODO: to ask
    description: "Veneta di origine ma bolognese di adozione, lavora dal 2017 nel mondo dell'editoria. Dal 2024 fa parte della squadra di Biancoenero, occupandosi specialmente di rapporti con le librerie, diritti stranieri e filiera commerciale.",
    // privilege: true
  },
  {
    name: "Maria Chiara Gritti",
    avatar: maria_chiara_gritti,
    description: "Psicologa, Psicoterapeuta, Ph. D in Scienze Umane, Fondatrice e Direttrice del Centro Dipendiamo®, Didatta in formazione presso la scuola di Psicoterapia Mara Selvini Palazzoli. Ideatrice di un innovativo percorso di guarigione sulla love addiction e sul narcisismo patologico, tiene corsi di formazione rivolti agli psicologi per diffondere l’applicazione del suo metodo.Autrice di due DUE LONG-SELLER 'LA PRINCIPESSA CHE AVEVA FAME D'AMORE' (SPERLING & KUPFER, 2017) E 'LA PRINCIPESSA CHE VOLEVA AMARE NARCISO' (SONZOGNO, 2021) e della LEGGENDA del FILO D’AMORE (SONZOGNO, 2024).",
    // privilege: true
  },
  {
    name: "Giulia Depentor",
    avatar: giulia_depentor,
    description: "È una scrittrice, podcaster e content producer. Ha pubblicato vari libri, tra cui “Immemòriam. I cimiteri e le storie che li abitano”, “Non vedo l'ora che venga domenica” e “Il vestito verde”. Da appassionata esploratrice dei luoghi di sepoltura e di ricerche genealogiche, ha lanciato i podcast “Camposanto” in cui accompagna gli ascoltatori alla scoperta di cimiteri in tutto il mondo e 'Microfilm' dove racconta i grandi eventi della storia dal punto di vista di chi non è finito in prima pagina.",
    // privilege: true
  },
  {
    name: "Giulio D’Antona",
    avatar: giulio_d_antona,
    description: "È uno scrittore, giornalista, traduttore e produttore cinematografico. Ha pubblicato vari libri, tra cui “Atlante dei luoghi infestati”, “Milano. Storia comica di una città tragica”, “Stand-Up Comedy” e “Non è un mestiere per scrittori”. Tra gli altri, ha scritto e curato il podcast “Bonvi”, prodotto da OnePodcast, dedicato al celebre e geniale fumettista Franco Bonvicini. Ha fondato Aguilar, la prima società di produzione italiana che si occupa esclusivamente di stand-up comedy e nel 2019 ha partecipato alla produzione dei primi tre Netflix Comedy Special italiani.",
    // privilege: true
  },
  {
    name: "Samuela Barbieri",
    avatar: samuela_barbieri,
    description: "Performer e danzatrice, si laurea in discipline dello spettacolo presso l'università di Ca’ Foscari a Venezia; Nel 1991 ottiene una borsa di studio in teatro e danza alla Sorbonne- Paris VIII. Lavora e studia con molti coreografi e registi in Italia e in Francia: Patricia Gloaguen, Lindsay Kemp, Chiara Reggiani, Luisa Casiraghi, Michele Abbondanza/Antonella Bertoni, Raffaella Giordano, Giorgio Rossi, Fabrizio Monteverde, Officina Danza, Lenz teatro rifrazioni, Giuseppe Emiliani, Ketty Adenzato. Tra gli spettacoli: Tararabum; Fili di intreccio e voci nel silenzio; Creation; Dove gli alberi incontrano il cielo; La piuma di Simurgh; Medea scizia; Studio all'imbrunire; Hansel e Gretel; Studio per una notte di mezza estate; Meglio sole; Invece del silenzio; Trame nell'erba; Frammenti; Appiccicosa vertigine; Nascosta; Nonostante; Fix Me; Tra terra e cielo/performance con Laure Keirouz; Bisbigli e lucciole; Ri_legami; Il tempo di Pandora. Video danza: Linea d'ombra (concorso Coreografo Elettronico); Animaterra; No_body (concorso Dança en foco-Rio de Janeiro); L'anima è miope (concorso Dança en foco-Rio de Janeiro); Studio Menguante; Kinderspiel; Uma nella polvere; Assenza-Presenza, Il tempo di Pandora. Progetti artistici: TransFigurazioni; Campi Visivi; Haiku Silent Room; In silenziosa assenza; Parole (poesia); In selvaggia armonia.",
    // privilege: true
  },
  {
    name: "Giorgio Ricci",
    avatar: giorgio_ricci,
    description: "Giorgio Ricci nel panorama della musica elettronica da oltre vent'anni come compositore, produttore e sound designer ha all'attivo oltre quaranta pubblicazioni ufficiali tra album, singoli, remix e soundtrack. Ha pubblicato, tra gli altri, con Contempo Record e la berlinese Dynamica Rec e collaborato con nomi della scena elettronica europea del calibro di Lassigue, Bendthause, Swamp Terrorist, Pankow. Compare, inoltre, in numerose compilation insieme a Clock Dva, Front 242, Mik Harris e molti altri protagonisti della musica industriale elettronica. Negli ultimi anni Ricci ha partecipato con le sue performance live a rassegne e manifestazioni come Asolo art film festival, Lago film festival e al Dancity festival di Foligno. Le sue installazioni sonore sono state presentate alla Neurotitan Gallery Arkaoda di Berlino. Sottili Polveri, il suo ultimo album da solista, è un lavoro sonoro ricco di riferimenti industrial che spazia tra generi affini alla dark ambient e al noise. L’album è stato presentato live al Centro di Cultura Italiana ad Amburgo.",
    // privilege: true
  },
  {
    name: "Ale Puro",
    avatar: ale_puro,
    description: "Mi presento, sono Ale Puro, vivo e lavoro a Vigevano. Affasciato dal mondo dei graffiti, inizio a fare murales con lo pseudonimo PURO e studiare le diverse forme di lettering. Studio presso l’accademia delle belle arti di Brera, in quel periodo mi avvicino alla cultura della street art e con lo sguardo rivolto verso il mondo dell’illustrazione, comincio a realizzare i miei primi figurativi, su diverse superfici e in diverse forme. Lo stile ispirato alle illustrazioni per l’infanzia, unito alla semplicità e poetica del mondo sognante dei più piccoli, mi ha permesso di poter lavorare e collaborare in diversi settori, dai privati alle aziende e soprattutto con le scuole. Ho collaborato con diverse realtà importanti a livello nazionale e sto iniziando a far conoscere anche i miei lavori nel mercato del nord America e Canada. Ho preso parte a diversi progetti editoriali tra cui ‘Il giardino in tasca’ (2016), ‘Mani illustrate’ (2019), ‘Grande come’ (2020), ‘La palazzina di Viale Libertà’ (2022). Nel 2023 mi aggiudico il premio internazionale del Silent Book Contest-Gianni De Conno Award con ‘’il ninja innamorato’’.",
    // privilege: true
  },
  {
    name: "Enrica Bordignon",
    avatar: enrica_bordignon,
    description: "Nel viaggio dal liceo artistico alla mediazione culturale ho capito che mettere insieme le cose dà risultati meravigliosi ben al di là della mera somma degli addendi. Appassionata fin da bambina di carta e cucito, ma anche di recupero dei materiali, ho trovato una grande metafora di questo concetto nello scrapbooking. Sempre in equilibrio tra lavoro sociale e arte, propongo viaggi creativi a chiunque abbia voglia di dedicarsi del tempo."
    // privilege: true
  },
  {
    name: "Enrico Marigonda",
    avatar: enrico_marigonda,
    description: "Classe 1991, nasce nella profonda campagna veneta. Dopo la laurea in Lingue e Letterature Straniere capisce che il fumetto e i libri per ragazzi sono il suo futuro. Grande appassionato di musica strana e detective, pensa sempre alla prossima storia da scrivere. Ha pubblicato 2 Detective e 1 bassotto e 2 Detective e 1 Bassotto – Indagine all’Acquapark. (Editrice Il Castoro, 2021 e 2023), entrambi illustrati da Gabriele Scarafia e I Casi di Borgo Mistero (Editrice Il Castoro, 2023) illustrato da Ilaria Perversi."
    // privilege: true
  },
  {
    name: "Claudio Marinaccio",
    avatar: claudio_marinaccio,
    description: " (Torino, 1982) è un autore di fumetti, strisce e articoli di graphic journalism per diverse riviste e quotidiani tra cui Internazionale, La Stampa e Skytg24. Ha pubblicato la graphic novel Trentatré raggi ionizzanti (Feltrinelli Comics, 2021) e l'inchiesta a fumetti sulla salute mentale dei giovani dal titolo Non può piovere per sempre (Skytg24, 2023). Il suo ultimo libro è Aimone l'airone (D Editore, 2024)."
    // privilege: true
  },
  {
    name: "Federica Ortolan",
    avatar: federica_ortolan,
    description: "Il mio nome è Federica Ortolan, sono autrice di albi illustrati. Dopo il liceo classico ho studiato Conservazione dei Beni Culturali a Venezia. Sono laureata in Scienze della Formazione Primaria all’Università di Padova con cui ho collaborato nell’ambito della ricerca pedagogica. Mi sono occupata prevalentemente dell'elaborazione cognitiva del bambino in relazione agli stimoli creativi. Ho avuto la fortuna di poter studiare e lavorare con Roberto Pittarello, allievo di Bruno Munari. Insieme a Fabio Grigenti, docente di “Storia della filosofia” presso l’Università di Padova ho scritto il capitolo Esercitare il sapere implicito. Dare voce ai bambini alla scoperta dei segreti della fisica, in E. Toffano Martini e P. De Stefani (a cura di), Ho fiducia in loro. Il diritto di bambini e adolescenti di essere ascoltati e di partecipare nell’intreccio delle generazioni, Carocci Editore, Roma 2017. Mi occupo di comunicazione non verbale con i bambini e gli adulti. Da qualche anno mi sono avvicinata allo psicodramma da cui derivano metodi applicati all’educazione e alla formazione."
    // privilege: true
  },
  {
    name: "Paolo Malaguti",
    avatar: paolo_malaguti,
    description: "Nato a Monselice (Padova) nel 1978. Con l'editrice Santi Quaranta ha pubblicato Sul Grappa dopo la vittoria (2009; Einaudi 2024), Sillabario veneto (2011), I mercanti di stampe proibite (2013). Con La reliquia di Costantinopoli (Neri Pozza 2015) ha partecipato al Premio Strega. Ha scritto inoltre Nuovo sillabario veneto (BEAT 2016), Prima dell'alba (Neri Pozza 2017), Lungo la Pedemontana (Marsilio 2018) e L'ultimo carnevale (Solferino 2019). Per Einaudi ha pubblicato Se l'acqua ride (2020 e 2023, Premio Latisana per il Nord-Est ex aequo, Premio Biella Letteratura e Industria, selezione Premio Campiello), Il Moro della cima (2022 e 2024, Premio Mario Rigoni Stern per la Letteratura Multilingue delle Alpi, Premio Monte Caio e Premio Vallombrosa), Piero fa la Merica (2023, Premio Acqui Storia, Premio internazionale Alessandro Manzoni), Fumana (2024) e Sul Grappa dopo la vittoria (2024)."
    // privilege: true
  },
  {
    name: "Nicolò Martin",
    avatar: nicolo_martin,
    description: "Nicolò Martin è un pianista naif che prova a scrivere qualche canzone. Il suo primo singolo si chiama “Edera” e farà parte del progetto musicale 'Esitazioni'. Il suo stile è evocativo e si rifà ad un passato nostalgico, in provincia. Le sue canzoni sono il piu delle volte dei piccoli elogi all'intimità."
    // privilege: true
  },
  {
    name: "Marco Leoni",    // TODO: to ask
    avatar: marco_leoni,
    description: "Marco Leoni nasce a Loreto e cresce in un piccolo paesino marchigiano davanti al mare. Si trasferisce a Firenze per frequentare l'Accademia di belle Arti, indirizzo di incisione e calcografia ed una scuola privata di illustrazione ed animazione. Da qualche anno vive a Bologna e si alterna tra lavoro come illustratore e narratore di giochi di ruolo per bambini e ragazzi. Nel tempo libero colleziona piccoli oggetti antichi e porta a passeggio i suoi granchi."
    // privilege: true
  },
  {
    name: "Carlo Zulianello",    // TODO: to ask
    avatar: carlo_zulianello,
    description: "Se penso al primo ricordo della mia vita, probabilmente si tratta di me a quattro anni con le bacchette della batteria in mano. Ho sempre saputo, e soprattutto desiderato, che la musica fosse parte integrante di ogni mio percorso, sia lavorativo che personale. Ogni suo aspetto mi affascina: lo studio, la pratica, la passione, il divertimento, ma cosa più importante le relazioni che vanno a crearsi grazie a essa. La mia missione è rendere consapevole ogni fascia d’età di quanto la musica possa essere uno strumento meravigliosamente potente negli aspetti più svariati della nostra vita quotidiana.",
    // privilege: true
  },
  {
    name: "Paolo Celotto",    // TODO: to ask
    avatar: paolo_celotto,
    description: "Paolo Celotto (Treviso) è un grafico libero professionista dal 1998. Alla collaborazione professionale con aziende, affianca l’interesse per l’editoria e le produzioni a stampa tipografica. Inizia così l’avventura di una propria stamperia, intitolata Neldubbiostampo – tipografia filopoetica, dedita alla produzione di libretti a tiratura limitata e di manufatti in letterpress.(https://paolocelotto.tumblr.com/)",
    // privilege: true
  },
  {
    name: "Andrea Zelio",    // TODO: to ask
    avatar: andrea_zelio,
    description: "Nasce in una bellissima giornata di giugno del 1964. E’ mezzogiorno in punto e oltre alle campane ci sono molte voci di bambini in giro. Le caratteristiche di questa terra generosa avranno un’influenza determinante per tutta la sua opera e gli studi veneziani contribuiranno a formare il suo orizzonte visivo ed immaginativo, costituito dalla molteplicità degli elementi e da un’atmosfera che sembra palpabile. Con il tempo diventerà pittore, narratore ed incisore. La sua narrativa, sin dagli inizi sembra essere rivolta ad un pubblico giovane, affascinato dalla scoperta e dal fantastico. Nel 2001 la sua opera prima Il Violino Azzurro risulta vincitrice del premio “Città di Penne-Mosca”. E’ pittore incisore e narratore. Al suo attivo risultano un centinaio di mostre. E’promotore del Progetto CaRtastorie, con l’Azienda Ulss4, A.I.T.Sa.M. e FabCube, giunto alla sua settima edizione. La Rai produce un servizio in TG2 “Storie” e nella rubrica “Tutto il Bello che c’è”. Dal 2015 sta lavorando al progetto “Le torri invincibili”. Si tratta di una raccolta di diciotto tele della dimensione di due metri di altezza per cinquanta di larghezza. Nel marzo 2021 scrive il testo per il video della Regione del Veneto che promuove un turismo senza confini, TOURISM4ALL. A settembre 2021 si sono inaugurate due sculture dedicate alla Grande Guerra che fanno parte del progetto europeo INTERREG Italia-Slovenia WALKofPEACE. E’autore di venti testi di narrativa, la maggior parte per ragazzi, in dicembre 2021 è uscita la nuova pubblicazione dal titolo “Il sogno del Re Giocattolo”, nel 2023 il nuovo testo “Farsi Parco” curato per la Regione del Veneto. Da piccolo aveva un sogno: diventare indiano d’America, farsi vento e conoscere suo nonno paterno. Per il momento è custode di un giardino di seimila metri quadrati ereditato da suo padre.",
    // privilege: true
  },
  {
    name: "Spezie Piccanti",    // TODO: to ask
    avatar: spezie_piccanti,
    description: "Spezie Piccanti*, nato a Treviso ma cresciuto nel cuore del veneziano, è un artista eclettico e appassionato collezionista di vinili dal 2010. La sua ricerca musicale lo ha portato a esplorare e assorbire influenze sonore provenienti da diverse parti d’Europa. Ha vissuto a Ibiza e Berlino, due città che hanno lasciato un’impronta indelebile sul suo stile: dalle sonorità house della scena ibizenca, fino ai ritmi elettronici, techno, e più sperimentali della capitale tedesca. La sua musica riflette un mix dinamico di culture e generi, che si fondono in una proposta sonora unica e ricercata.",
    // privilege: true
  },
]

export default hostDescription