class News {
  constructor() {
    this.id = ""
    this.wallpaper = ""
    this.title = ""
    this.description = ""
    this.date = ""
  }
}

export default News