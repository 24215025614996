import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import MyTimeline from '../../components/my_timeline'
import {ReactComponent as Logo} from "../../media/img/festival/Logo.svg"
import { Button } from 'primereact/button';
// import { Carousel } from 'primereact/carousel';
import Carousel from 'react-bootstrap/Carousel';
import { Avatar, Chip } from '@mui/material';
import { Dialog } from 'primereact/dialog';   
import eventSchedule from './event_schedule';
import hostDescription from './host_description';
import ExpandableTable from '../../components/exapndable_table';
import "./style.css"
import Countdown from 'react-countdown';
import Azienda1 from '../../media/img/festival/azienda-01.png';
import Azienda2 from '../../media/img/festival/azienda-02.png';
import Azienda3 from '../../media/img/festival/azienda-03.png';
import Azienda4 from '../../media/img/festival/azienda-04.png';
import Azienda5 from '../../media/img/festival/azienda-05.png';
import Azienda6 from '../../media/img/festival/azienda-06.png';
import Azienda7 from '../../media/img/festival/azienda-07.png';
import Azienda8 from '../../media/img/festival/azienda-08.png';
import Azienda9 from '../../media/img/festival/azienda-09.png';
import Azienda10 from '../../media/img/festival/azienda-10.png';
import Azienda11 from '../../media/img/festival/azienda-11.png';
import Azienda12 from '../../media/img/festival/azienda-12.png';
import Azienda13 from '../../media/img/festival/azienda-13.png';
import Azienda14 from '../../media/img/festival/azienda-14.png';
import Azienda15 from '../../media/img/festival/azienda-15.png';
import Azienda16 from '../../media/img/festival/azienda-16.png';
import Azienda17 from '../../media/img/festival/azienda-17.png';
import Azienda18 from '../../media/img/festival/azienda-18.png';
import Azienda19 from '../../media/img/festival/azienda-19.png';
import Azienda20 from '../../media/img/festival/azienda-20.png';
import Azienda21 from '../../media/img/festival/azienda-21.png';
import Azienda22 from '../../media/img/festival/azienda-22.png';
import Azienda23 from '../../media/img/festival/azienda-23.png';
import Azienda24 from '../../media/img/festival/azienda-24.png';
import Azienda25 from '../../media/img/festival/azienda-25.png';
import Azienda26 from '../../media/img/festival/azienda-26.png';
import Azienda27 from '../../media/img/festival/azienda-27.png';
import Azienda28 from '../../media/img/festival/azienda-28.png';
import Azienda29 from '../../media/img/festival/azienda-29.png';
import Azienda30 from '../../media/img/festival/azienda-30.png';
import Azienda31 from '../../media/img/festival/azienda-31.png';
import Azienda32 from '../../media/img/festival/azienda-32.png';
import Azienda33 from '../../media/img/festival/azienda-33.png';
import Azienda34 from '../../media/img/festival/azienda-34.png';
import Azienda35 from '../../media/img/festival/azienda-35.png';
import Azienda36 from '../../media/img/festival/azienda-36.png';
import Azienda37 from '../../media/img/festival/azienda-37.png';
import Azienda38 from '../../media/img/festival/azienda-38.png';
import comune from '../../media/img/festival/comune.png'

import { height } from '@mui/system';

function FestivalPage(props) {
  let mobileMode = props.mobileMode
  const timeReference = "09 Sep 2024 14:30:00"
  // const timeReference = "09 Sep 2023 12:00:00"
  const [firstStateVisible, setFirstStateVisible] = useState(true)
  // const [firstStateVisible, setFirstStateVisible] = useState(false)
  const [sponsor, setSponsor] = useState([])
  const [hostVisible, setHostVisible] = useState(false)
  const [hostList, setHostList] = useState([])
  const [ticketAlertVisible, setTicketAlertVisible] = useState(false)
  const [isEventbriteEventSetted, setIsEventbriteEventSetted] = useState(false)
  const [itsTime, setItsTime] = useState(new Date(Date.now()) > new Date(Date.parse(timeReference)))
  const sponsorList = [
    Azienda1, Azienda2, Azienda3, Azienda4, Azienda5, Azienda6, Azienda7, 
    Azienda8, Azienda9, Azienda10, Azienda11, Azienda12, Azienda13, 
    Azienda14, Azienda15, Azienda16, Azienda17, Azienda18, Azienda19, 
    Azienda20, Azienda21, Azienda22, Azienda23, Azienda24, Azienda25, 
    Azienda26, Azienda27, Azienda28, Azienda29, Azienda30, Azienda31, 
    Azienda32, Azienda33, Azienda34, Azienda35, Azienda36, Azienda37, 
    Azienda38, Azienda38
  ];
  const sectionColors = {
    "--dark_blue" : "var(--festival-main-color)" // sets the background color
  }

  function mixArray(array_name) {
    for (let i = array_name.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [array_name[i], array_name[j]] = [array_name[j], array_name[i]];
    }
    return array_name
  }

  function mixHost() {
      let privileged = hostDescription.filter(host => host.privilege)
      let not_privileged = hostDescription.filter(host => !host.privilege)
  
      privileged = mixArray(privileged)
      not_privileged = mixArray(not_privileged)
  
      setHostList([...privileged, ...not_privileged])
  }

  function setEventbriteEvent() {
    if(!isEventbriteEventSetted) {
      // Eventribe
      var exampleCallback = function() {
      };

      window.EBWidgets.createWidget({
          widgetType: 'checkout',
          eventId: '1012125303027',
          modal: true,
          modalTriggerElementId: 'eventbrite-widget-modal-trigger-712179567257',
          onOrderComplete: exampleCallback,
      });
      setIsEventbriteEventSetted(true)
    }

  }

  useEffect(() => {
    if(itsTime) {
      mixHost()
      // changing background color
      let root = document.querySelector(":root")
      for(let variableName of Object.keys(sectionColors)) {
        let colorHEX = sectionColors[variableName]
        root.style.setProperty(variableName, colorHEX)
      }

      setTimeout(() => {
        setFirstStateVisible(false)
        setSponsor(mixArray(sponsorList))
      }, 3800)
    }
  }, [itsTime])
  
  return (
    <div id="festival_page">
      {
        !itsTime ? 
        <div className="countdown_container">
          <Countdown renderer={(info) => <CountDownFormat info={info} />}  date={Date.parse(timeReference)} onComplete={() => setTimeout(setItsTime(true), 1000)}/>
        </div> :
        <>
          {
          firstStateVisible ? 
            <div className="first_sentence_container centered">
              <Logo id="initial_logo"/>
            </div> : 
            <div >
              <Row className="festival_content">
              <Col xl="1" ></Col>
                <Col xl="4" className="presentation">
                  <div className="content_container">
                    <div className="mb-5 inline">
                      <div className="logo centered">
                        <Logo />
                      </div>
                      <div className="carousel_container">
                        <Carousel controls={false} indicators={false} interval={1500}>
                          {
                            Array.from({ length: Math.floor(39 / 3)}, (_, index) => index * 3).map((idx) => (
                              <Carousel.Item>
                                <Row>
                                  <Col className="centered"><img src={sponsor[idx]} className="img-fluid" thumbnail/></Col>
                                  <Col className="centered"><img src={sponsor[idx + 1]} className="img-fluid" thumbnail/></Col>
                                  <Col className="centered"><img src={sponsor[idx + 2]} className="img-fluid" thumbnail/></Col>
                                </Row>
                              </Carousel.Item>
                            ))
                          }
                        </Carousel>
                      </div>
                    </div>
                    <h1 className="festival_name mb-4">Estratto Festival</h1>
                    <p className="festival_description">
                      Vogliamo distillare la cultura, la comunità, l’arte e la bellezza che ci circondano: qui troverete l’estratto più puro di ciò che conta davvero, che sia un libro, una canzone, un drink o un momento condiviso.<br/><br/>
                      BENVENUTI AL FESTIVAL DELL’ESSENZIALE.
                    </p>
                    <div className="host_container">
                      <h6 className="host_title">Ospiti</h6>
                      <div className="host_container">
                        <Chip
                          color="info"
                          label={hostList.length > 0 ? hostList[0].name : ""}
                          avatar={<Avatar src={hostList.length > 0 ? hostList[0].avatar : ""}/>}
                          clickable/>
                        <Chip
                          color="info"
                          label={hostList.length > 0 ? hostList[1].name : ""}
                          avatar={<Avatar src={hostList.length > 0 ? hostList[1].avatar : ""}/>}
                          clickable/>
                        <Chip
                          color="info"
                          label="Mostra tutti"
                          clickable
                          onClick={() => setHostVisible(true)}/>
                        <Dialog
                          draggable={false}
                          style={{ width: '50vw', margin: mobileMode ? 0 : 12 }}
                          breakpoints={{ '960px': '75vw', '641px': '100vw' }} 
                          visible={hostVisible}
                          header="Ospiti"
                          dismissableMask
                          position={mobileMode ? "bottom" : "center"}
                          onHide={() => setHostVisible(false)}>
                          <ExpandableTable headerTitle={["Foto", "Nome"]} content={hostList} />
                        </Dialog>
                      </div> 
                    </div>
                    <hr/>
                    <Button onClick={() => setTicketAlertVisible(true)} label="Prenota il tuo posto!" className="subscribe_button bounce"/>
                    {/* <Button onClick={openTicket} label="Le prenotazioni inizieranno tra qualche ora" className="subscribe_button bounce"/> */}
                    {/* <Button style={{padding: 2}} label="Scopri Libreria Raggiungibile" className="subscribe_button bounce"/>                     */}
                    <a className="homepage_link mt-4" href="/">Scopri libreria raggiungibile</a>
                    <Dialog
                      draggable={false}
                      style={{ width: '30vw', margin: 12 }}
                      breakpoints={{ '960px': '40vw', '641px': '100vw' }} 
                      visible={ticketAlertVisible}
                      header="Informazioni biglietti"
                      dismissableMask
                      position={mobileMode ? "bottom" : "center"}
                      onShow={setEventbriteEvent}
                      onHide={() => setTicketAlertVisible(false)}>
                        <div>
                          <p>Tutti i biglietti a pagamento sono acquistabili esclusivamente presso Libreria Raggiungibile.</p>
                          <Button onClick={() => setTicketAlertVisible(false)} label="Ok" id="eventbrite-widget-modal-trigger-712179567257" className="open_ticket_button"/>
                        </div>
                      </Dialog>
                  </div>
                </Col>
                <Col xl="1"></Col>
                <Col xl="5" className="program">
                  <MyTimeline mobileMode={mobileMode} info={eventSchedule}/>
                </Col>
                <Col xl="1"></Col>
              </Row>
            </div>
          }
        </>
      }
    </div>
  )
}

function CarouselItem(props) {
  let info = props.info
  return(
    <div className="carousel_item">
      <img src={info} className="img-fluid"/>
    </div>
  )
}

function CountDownFormat(props) {
  let info = props.info.formatted
  let time = info.days + ":" + info.hours + ":" + info.minutes + ":" + info.seconds

  return (
    <div>
      <h3 className="sentence mb-5">Impaziente di sapere il programma?</h3>
      <h1 className="time mb-5">{time}</h1>
      <p>Disponibile dal 9 Settembre 2024</p>
    </div>
  )
}


export default FestivalPage