import { Avatar, IconButton, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useState } from 'react'
import { Collapse, Table } from 'react-bootstrap'
import PropTypes from 'prop-types';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
function ExpandableTable(props) {
  let header_title = props.headerTitle
  let content = props.content

  return (
    <TableContainer>
      <Table>
        <TableHead className="extendable_table">
          <TableRow>
            {
              header_title.map(title => <TableCell><p className="font_family m-0">{title}</p></TableCell>)
            }
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {
            content.map(info => <Row info={info}/>)
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function Row(props) {
  let info = props.info
  const [open, setOpen] = useState(false)

  return (
    <>
      <TableRow >
        <TableCell><Avatar src={info.avatar} /></TableCell>
        <TableCell><p className="font_family m-0">{info.name}</p></TableCell>
        <TableCell>
          {
            info.description ? 
              <IconButton
              size="small"
              onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton> :
              ""
          }
        </TableCell>
      </TableRow> 
      <TableRow>
        <TableCell colSpan={3}>
          <Collapse in={open} timeout="auto">
            <p className="font_family">{props.info.description}</p>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

ExpandableTable.propTypes = {
  headerTitle: PropTypes.array.isRequired,
  content: PropTypes.array.isRequired
}

export default ExpandableTable